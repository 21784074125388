import React, { memo } from "react";
import { clone } from "@bsgp/lib-core";
import { DonutChart } from "@ui5/webcomponents-react-charts";
import ChartPlaceholder from "./ChartPlaceholder";
const Builder = (props) => {
  const {
    value,
    dimensions,
    measures
  } = props;
  const dim = dimensions[0];
  const newDim = clone(dim);
  delete newDim.name;
  delete newDim.properties;
  delete newDim.type;
  newDim.accessor = dim.name;
  const mes = measures[0];
  const newMes = clone(mes);
  delete newMes.name;
  delete newMes.properties;
  delete newMes.type;
  newMes.accessor = mes.name;
  return /* @__PURE__ */ React.createElement(
    DonutChart,
    {
      dataset: value || [],
      dimension: newDim,
      measure: newMes,
      ChartPlaceholder
    }
  );
};
export default memo(Builder);
